(function() {
    'use strict';

    angular.module('app')
        .factory('ProductService', ProductService)

    ProductService.$inject = ['$http', '$q', '$cacheFactory'];

    function ProductService($http, $q, $cacheFactory) {
        // sizes
        var productQueryCache = $cacheFactory.get('productsQuery');
        var fuzzySearchCache = $cacheFactory.get('fuzzyProductsQuery');
        if (!productQueryCache) {
            productQueryCache = $cacheFactory('productsQuery', {capacity: 1});
            fuzzySearchCache = $cacheFactory('fuzzyProductsQuery', {capacity: 1});
        }

        function getProductByUrl(url, category) {
            var route = '/api/products/name/' + url;
            if (category) {
                route += '/' + category
            }
            return $http.get(route)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function getProductBySku(sku) {
            var route = '/api/products/' + sku;
            return $http.get(route)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }


        function fetchProducts(query, shouldCache) {
            if (query) {
                var queryString = Qs.stringify(query, {encode: true});
                return $http.get('/api/products/filter?' + queryString)
                    .then(function (response) {
                        return response.data;
                    })
                    .catch(function (err) {
                        return $q.reject(err);
                    })
            }
        }

        function fuzzySearchProducts(queryString) {
            return $http.get('/api/products/search/fuzzy/' + queryString, {cache: false})
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    showError(err.data.message);
                    return $q.reject(err);
                })
        }

        function getComplimentaryStyles(sku){
            return $http.get('/api/products/complimentaryStyles/' + sku)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        function getRelatedProducts(sku) {
            return $http.get('/api/products/relatedProducts/' + sku)
                .then(function(response) {
                    return response.data;
                })
                .catch(function(err) {
                    return $q.reject(err);
                })
        }

        return {
            getProductByUrl: getProductByUrl,
            getProductBySku: getProductBySku,
            fetchProducts: fetchProducts,
            fuzzySearchProducts: fuzzySearchProducts,
            getRelatedProducts: getRelatedProducts,
            getComplimentaryStyles: getComplimentaryStyles
        };
    }

})();

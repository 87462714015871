(function() {
    'use strict';

    angular.module('app')
        .factory('AnalyticsService', AnalyticsService)

    AnalyticsService.$inject = [];

    // private helper functions //

    function transformItemLinesToGoogleAnalyticItems(itemLines, products) {
        var googleItems = _.map(itemLines, function(itemLine) {
            var foundProduct = _.find(products, function(product){
                return product.sku === itemLine.sku
            })
            return transformItemLineToGoogleAnalyticsItem({
                itemLine: itemLine,
                product: foundProduct
            });
        });
        return googleItems;
    }

    // function to transform cart lineitem/product combination to google analytics item object
    //todo: find a way to pipe in prints and other information to google analytics (via custom metrics/dimensions)
    function transformItemLineToGoogleAnalyticsItem(itemLineAndProduct) {
        return {
            item_id: itemLineAndProduct.itemLine.sku,
            item_name: itemLineAndProduct.itemLine.productName,
            price: itemLineAndProduct.itemLine.price,
            quantity: itemLineAndProduct.itemLine.totalQty,
            item_variant: itemLineAndProduct.itemLine.details.colourName,
            item_brand: itemLineAndProduct.product ? itemLineAndProduct.product.brand: null,
            item_category: itemLineAndProduct.product ? itemLineAndProduct.product.type: null,
            item_category2: itemLineAndProduct.product ? itemLineAndProduct.product.category: null
        }
    }


    // Google GA4 Analytics
    function AnalyticsService() {

        function trackSearchEvent(keyword) {
            gtag("event", "search", {
                search_term: keyword
            });

            if (fbq){
                fbq('track', 'Search', {
                    search_string: keyword // Replace with the user's search query
                });
            }
        }

        function trackViewItemEvent(product) {
            var dummyItem = {
                totalQty: 1,
                price: product.colours[0].price,
                sku: product.sku,
                productName: product.name,
                details: {
                    colourName: null
                },
            }
            var googleAnalyticsItem = transformItemLineToGoogleAnalyticsItem({
                itemLine: dummyItem,
                product: product
            });

            gtag("event", "view_item", {
                value: dummyItem.price,
                items: [googleAnalyticsItem]
            })
        }

        function trackAddItemsToCart(itemLines, products) {
            var items = transformItemLinesToGoogleAnalyticItems(itemLines, products);
            var value = _.reduce(itemLines, function(acc, itemLine) {
                return acc + (itemLine.price * itemLine.totalQty);
            }, 0)
            gtag("event", "add_to_cart", {
                value: value,
                items: items
            })

            if (fbq){
                fbq('track', 'AddToCart', {
                    value: value, // Replace with the price of the product added to the cart
                    currency: "CAD"
                });
            }

            if (window.VWO){
                // Do not change anything in the following two lines
                window.VWO = window.VWO || [];
                VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};
                // Replace the property values with your actual values
                VWO.event("addToCart", {
                    "value": 100
                });
            }
        }

        function trackBeginCheckout(itemLines, products, cartTotal) {
            var items = transformItemLinesToGoogleAnalyticItems(itemLines, products);
            gtag("event", "begin_checkout", {
                value: cartTotal,
                items: items
            });
        }

        function trackBeginCheckoutTimeline(itemLines, products, cartTotal) {
            var items = transformItemLinesToGoogleAnalyticItems(itemLines, products);
            gtag("event", "begin_checkout_timeline", {
                value: cartTotal,
                items: items
            });
        }

        function trackBeginCheckoutFinalReview(itemLines, products, cartTotal) {
            var items = transformItemLinesToGoogleAnalyticItems(itemLines, products);
            gtag("event", "begin_checkout_final_review", {
                value: cartTotal,
                items: items
            });
        }

        function trackPurchase(order, products) {
            var items = transformItemLinesToGoogleAnalyticItems(order.listOfItems, products);
            gtag("event", "purchase", {
                transaction_id: order.number,
                value: order.subTotalPrice,
                shipping: order.shippingCost,
                tax: order.gst + order.pst,
                items: items
            })

            if ($wc_leads){
                $wc_leads.track.transaction
                (
                    order.number,
                    order.subTotalPrice, //Replace 25.65 with your order total - Required
                    0, //Replace 2.56 with your order tax - Required (Use 0 for no value)
                    0, //Replace 1.53 with your order shipping - Required (Use 0 for no value)
                    {
                        'Full Name': order.emailAddress,
                        'Email Address': order.emailAddress,
                        'Phone Number' : order.phoneNumber
                    }
                )
            }

            if (fbq){
                fbq('track', 'Purchase', {
                    value: order.subTotalPrice, // Replace with the total order value
                    currency: 'CAD',
                    order_id : order.number
                });
            }

        }

        function customTrackUploadDesign(url){
            gtag("event", "designer_image_upload", {
                design_id: String(url)
            })

            if (window.VWO){
                VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};
                // Replace the property values with your actual values
                VWO.event("uploadImage", {
                    "uploadImage": url
                });
            }

            if (fbq){
                fbq('trackCustom', 'ImageUpload', {});
            }
        }


        function customTrackSaveDesign(design) {
            gtag("event", "save_design", {
                design_number: String(design.number),
                design_link: design.link
            })

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Design', //Replace User with the event category - Required
                    'Save', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': design.email,
                        'Email Address': design.email
                    }
                )
            }
        }

        function customTrackApproveQuote(order, products) {
            var items = transformItemLinesToGoogleAnalyticItems(order.listOfItems, products)
            gtag("event", "approve_quote", {
                transaction_id: order.number,
                value: parseInt(order.subTotalPrice),
                order_number_quote_approved: String(order.number),
                order_subtotal_quote_approved: parseInt(order.subTotalPrice),
                items: items
            })
        }

        function customTrackPlaceOrder(order, products) {
            var items = transformItemLinesToGoogleAnalyticItems(order.listOfItems, products)
            gtag("event", "place_order", {
                transaction_id: order.number,
                value: parseInt(order.subTotalPrice),
                order_number: String(order.number),
                order_subtotal: parseInt(order.subTotalPrice),
                items: items
            })

            if (window.VWO){
                window.VWO = window.VWO || [];
                VWO.event = VWO.event || function () {VWO.push(["event"].concat([].slice.call(arguments)))};
                VWO.event("orderPlacedValue", {
                    "price": 100,
                    // "price": parseInt(order.subTotalPrice),
                    "number": order.number
                });
            }

            if(fbq){
                fbq('trackCustom', 'PlaceOrder', {
                    order_id: order.number, // Replace with the actual order ID
                    value: order.subTotalPrice, // Replace with the total order value
                    currency: 'CAD'
                });
            }

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Order', //Replace User with the event category - Required
                    'Created', //Replace Signup with the event action - Required
                    order.number, //Replace New with the event label - Optional
                    0, //Replace 5 with the event value - Optional
                    {
                        'Full Name': order.emailAddress,
                        'Email Address': order.emailAddress,
                        'Phone Number' : order.phoneNumber,
                        'Subtotal' : parseInt(order.subTotalPrice)
                    }
                )
            }
        }

        function customTrackMeetingBooked(bookee) {
            gtag("event", "meeting_booked", {
                meeting_bookee: bookee
            });
        }

        function customTrackPromotionalOffer(ticket_id,email,phone) {
            gtag("event", "promotional_offer", {
                offer_id: ticket_id
            });

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Form Submission', //Replace User with the event category - Required
                    'Promotional Offer', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': email,
                        'Email Address': email,
                        'Phone Number' : phone
                    }
                )
            }

            if(fbq){
                fbq('trackCustom', 'PromotionalOfferSubmission', {
                    form_id: ticket_id, // Optional: Unique identifier for the form
                    user_email: email // Optional: User's email (if captured)
                });
            }
        }


        function customTrackContactUs(ticket_id,email,phone) {
            gtag("event", "contact_us", {
                zendesk_ticket_id: ticket_id
            });

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Form Submission', //Replace User with the event category - Required
                    'Contact Us', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': email,
                        'Email Address': email,
                        'Phone Number' : phone
                    }
                )
            }

            if(fbq){
                fbq('trackCustom', 'ContactUsFormSubmission', {
                    form_id: ticket_id, // Optional: Unique identifier for the form
                    user_email: email // Optional: User's email (if captured)
                });
            }
        }

        function customTrackRequestQuote(quoteId,email,phone) {
            gtag("event", "request_quote", {
                quote_id: quoteId
            });

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Form Submission', //Replace User with the event category - Required
                    'Desktop Quote Request', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': email,
                        'Email Address': email,
                        'Phone Number' : phone
                    }
                )
            }

            if(fbq){
                fbq('trackCustom', 'RequestQuoteSubmission', {
                    form_id: quoteId, // Optional: Unique identifier for the form
                    user_email: email // Optional: User's email (if captured)
                });
            }
        }

        function customTrackMobileRequestQuote(quoteId,email,phone) {
            gtag("event", "mobile_request_quote", {
                mobile_quote_id: quoteId
            });

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Form Submission', //Replace User with the event category - Required
                    'Mobile Quote Request', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': email,
                        'Email Address': email,
                        'Phone Number' : phone
                    }
                )
            }

            if(fbq){
                fbq('trackCustom', 'MobileRequestQuoteSubmission', {
                    form_id: quoteId, // Optional: Unique identifier for the form
                    user_email: email // Optional: User's email (if captured)
                });
            }
        }

        function customTrackSignUp(email){
            gtag("event", "user_signup");
            if ($wc_leads){
                $wc_leads.track.event
                (
                    'User', //Replace User with the event category - Required
                    'Sign Up', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': email,
                        'Email Address': email
                    }
                )
            }

        }

        function customTrackDesignHelp(designHelpId,email,phone) {
            gtag("event", "design_help_request", {
                design_help_id: designHelpId
            });

            if ($wc_leads){
                $wc_leads.track.event
                (
                    'Form Submission', //Replace User with the event category - Required
                    'Design Help', //Replace Signup with the event action - Required
                    false, //Replace New with the event label - Optional
                    false, //Replace 5 with the event value - Optional
                    {
                        'Full Name': email,
                        'Email Address': email,
                        'Phone Number' : phone
                    }
                )
            }

            if(fbq){
                fbq('trackCustom', 'DesignHelpSubmission', {
                    form_id: designHelpId, // Optional: Unique identifier for the form
                    user_email: email // Optional: User's email (if captured)
                });
            }
        }

        return {
            // google recommended events
            trackSearchEvent: trackSearchEvent,
            trackViewItemEvent: trackViewItemEvent,
            trackAddItemsToCart: trackAddItemsToCart,
            trackBeginCheckout: trackBeginCheckout,
            trackPurchase: trackPurchase,

            // custom events
            trackBeginCheckoutTimeline: trackBeginCheckoutTimeline,
            trackBeginCheckoutFinalReview: trackBeginCheckoutFinalReview,
            customTrackSaveDesign: customTrackSaveDesign,
            customTrackPlaceOrder: customTrackPlaceOrder,
            customTrackApproveQuote : customTrackApproveQuote,
            customTrackMeetingBooked: customTrackMeetingBooked,
            customTrackContactUs: customTrackContactUs,
            customTrackPromotionalOffer: customTrackPromotionalOffer,
            customTrackRequestQuote: customTrackRequestQuote,
            customTrackMobileRequestQuote: customTrackMobileRequestQuote,
            customTrackDesignHelp: customTrackDesignHelp,
            customTrackUploadDesign : customTrackUploadDesign,
            customTrackSignUp : customTrackSignUp
        };
    }

})();
